<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                {{ $store.state.sysenv.codePropagated.name }}
                <small>Writable Button</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd()"></button>
                <div class="j_search type01">
                  <input v-model="search" type="search" name="q" placeholder="Filter and search" />
                  <button type="submit">
                    <v-icon>mdi-magnify</v-icon>
                  </button>
                </div>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            item-key="idx"
            hide-actions
            :headers="headers"
            :items="wButton"
            :search="search"
            @filtered="onFiltered"
            @sorted="onSorted"
          >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus(props)">
                <td style="text-align: center;">{{ props.item.no }}</td>
                <td>{{ props.item.phaseName }}</td>
                <td>{{ props.item.groupName }}</td>
                <td>{{ props.item.itemName }}</td>
                <td>{{ props.item.subItemName }}</td>
                <td style="text-align: center;">{{ props.item.buttonCode }}</td>
                <td>{{ props.item.reportName }}</td>
                <td>{{props.item.accessGroupName}}</td>
                <td>{{props.item.accessUser}}</td>
                <td>{{ props.item.desc }}</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>

      <j-form-modal
        title="Writable Button"
        ref="formHandler"
        @create="add()"
        @edit="edit()"
        @delete="del()"
        @cancel="cancel()"
        :formMode="formode"
        :resetable="resetable()"
        :opened.sync="modOpen"
      >
        <v-container>
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-flex xs12 class="wrap__select">
                <v-select
                  v-model="selectedItem.phaseCode"
                  class="wrap__select"
                  item-text="phaseName"
                  item-value="phaseCode"
                  label="Phase Title"
                  placeholder="Select a Phase Title"
                  dense
                  required
                  @input="phaseChanged"
                  :menu-props="{contentClass:'single_select'}"
                  :items="phaseOptions"
                  :rules="defaultRules"
                ></v-select>
              </v-flex>

              <v-flex xs12 class="wrap__select">
                <v-select
                  v-model="selectedItem.groupCode"
                  class="wrap__select"
                  item-text="groupName"
                  item-value="groupCode"
                  label="Group Title"
                  placeholder="Select a Group Title"
                  dense
                  @input="groupChanged"
                  :menu-props="{contentClass:'single_select'}"
                  :items="groupOptions"
                  :rules="defaultRules"
                ></v-select>
              </v-flex>

              <v-flex xs12 class="wrap__select">
                <v-select
                  v-model="selectedItem.itemCode"
                  class="wrap__select"
                  item-text="itemName"
                  item-value="itemCode"
                  label="Item Title"
                  placeholder="Select a Item Title"
                  dense
                  @input="itemChanged"
                  :menu-props="{contentClass:'single_select'}"
                  :items="itemOptions"
                  :rules="defaultRules"
                ></v-select>
              </v-flex>
            </v-flex>

            <v-flex xs12 sm6>
              <v-flex xs12 class="wrap__multiselect">
                <v-select
                  v-model="accessGroup"
                  item-text="name"
                  return-object
                  label="Accessable Permission"
                  multiple
                  placeholder="Select permissions to apply"
                  :items="accessGroupOptions"
                  :menu-props="{ maxHeight: '400', contentClass:'multi_select' }"
                ></v-select>
              </v-flex>

              <v-flex xs12 class="wrap__multiselect">
                <v-select
                  v-model="accessUser"
                  item-text="userName"
                  return-object
                  label="Accessable Members"
                  multiple
                  placeholder="Select users to assign"
                  :items="accessUserOptions"
                  :menu-props="{ maxHeight: '400', contentClass:'multi_select' }"
                ></v-select>
              </v-flex>
            </v-flex>

            <v-flex xs12 class="wrap__option_group">
              <span class="label">Sub-Item</span>
              <div class="option_group">
                <label for="0">
                  <input
                    v-model="selectedItem.subItem"
                    type="radio"
                    name="subItem"
                    id="0"
                    value="0"
                  />
                  None
                </label>
                <label :for="option.idx" v-for="option in subItemOptions" :key="option.index">
                  <input
                    v-model="selectedItem.subItem"
                    type="radio"
                    name="subItem"
                    :id="option.idx"
                    :value="option.idx"
                  />
                  {{ option.subItemName }}
                </label>
              </div>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.reportName"
                required
                label="Report Title"
                placeholder="Input/Edit Title of report"
                :rules="defaultRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-model="selectedItem.desc" name="desc" label="Description"></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </j-form-modal>
    </div>
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { AdminService, SystemService } from "@/services"
import JFormModal from "@/components/floating/JFormModal"

export default {
  name: "sysenv-writable-button",
  components: {
    JFormModal
  },
  data: () => ({
    systemService: null,
    adminService: null,
    modOpen: false,
    phaseOptions: [],
    groupOptions: [],
    itemOptions: [],
    prevCodes: { phaseCode: "", groupCode: "", itemCode: "" },
    subItemOptions: [],
    accessUserOptions: [],
    accessUser: [],
    accessGroupOptions: [],
    accessGroup: [],
    search: "",
    selected: [],
    selectedIndex: -3,
    selectedItem: {},
    selectedPrevItem: [],
    headers: [
      { type: "conf", text: "No",                     align: "center",  value: "no",           width: 50,  sortable: false },
      { type: "text", text: "Phase Title",            align: "left",    value: "phaseName",    width: 120, sortable: true  },
      { type: "text", text: "Group Title",            align: "left",    value: "groupName",    width: 120, sortable: true  },
      { type: "text", text: "Item Title",             align: "left",    value: "itemName",     width: 120, sortable: true  },
      { type: "text", text: "Sub-Item Title",         align: "left",    value: "subItemName",  width: 120, sortable: true  },
      { type: "text", text: "Button Code",            align: "center",  value: "buttonCode",   width: 100, sortable: true  },
      { type: "text", text: "Report Title",           align: "left",    value: "reportName",   width: 190, sortable: true  },
      { type: "text", text: "Accessible Permission",  align: "left",    value: "accessName",   width: 200, sortable: true  },
      { type: "text", text: "Accessible User",        align: "left",    value: "accessName",   width: 200, sortable: true  },
      { type: "text", text: "Desciption",             align: "left",    value: "desc",                     sortable: false }
    ],
    wButton: [],
    loading: false,
    select: null,
    formode: __C.FORM.EDIT_MODE_NEW,
    valid: false,
    defaultRules: [v => !!v || "Required"],
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => { }
  }),
  watch: {
    accessUser(val) {
      let memberNames = []
      val.forEach(m => { memberNames.push(m.userName) })
      this.selectedItem.accessUser = memberNames.join(",")
    },
    accessGroup(val) {
      let groupCodes = []
      val.forEach(m => { groupCodes.push(m.code) })
      this.selectedItem.accessGroup = groupCodes.join(",")
    },
    codePropagated: {
      handler(val) {
        //
        let phaseHeaderIndex = this.headers.findIndex( h => h.value === "phaseName")
        // to retain or set page's default status in 'Add'
        this.selectedIndex = -3
        // retrieve the list for the new condition
        this.systemService.getWButton(this.setWButton, val.parentCode, val.groupCode, val.code)

        // this.selectedItem.phaseCode = val.parentCode
        // this.selectedItem.groupCode = val.groupCode
        // this.selectedItem.itemCode = val.code
        // this.phaseChanged(val.parentCode)
        // this.groupChanged(val.groupCode)
      },
      deep: true
    }
  },
  created() {
    this.adminService = new AdminService()
    this.systemService = new SystemService()
  },
  mounted() {
    // to avoid 'Access-Control-Allow-Origin' CORS policy error
    setTimeout(() => {
      this.systemService.getNavPhase(res => {
        if (!res) this.phaseOptions = []
        else this.phaseOptions = res

        this.systemService.getCodeIndex("PERMISSION", res => {
          if (!res) this.accessGroupOptions = []
          else this.accessGroupOptions = res

          this.adminService.getMembers("ALL", res => {
            if (!res) this.accessUserOptions = []
            this.accessUserOptions = res
            this.systemService.getWButton(this.setWButton, this.codePropagated.parentCode, this.codePropagated.groupCode, this.codePropagated.code)
          })
        })
      })
    }, 200)
  },
  computed: {
    codePropagated: {
      get() { return this.$store.state.sysenv.codePropagated },
      set(value) { this.$store.commit("sysenv/codePropagate", value) }
    }
  },
  methods: {
    phaseChanged(phaseCode) {
      this.itemOptions = []
      if(this.prevCodes.groupCode == "") this.selectedItem.groupCode = ""
      
      this.systemService.getNavGroup(phaseCode, res => {
        if (!res) this.groupOptions = []
        else this.groupOptions = res
        this.prevCodes.groupCode = ""
      })
    },
    groupChanged(groupCode) {
      if(this.prevCodes.itemCode == "") this.selectedItem.itemCode = ""
      this.systemService.getNavItem(
        res => { !res ? this.itemOptions = [] : this.itemOptions = res; this.prevCodes.itemCode = "" },
        this.selectedItem.phaseCode,
        groupCode
      )
    },
    itemChanged(itemCode) {
      if (!!this.selectedItem.phaseCode && !!this.selectedItem.itemCode && this.selectedItem.itemCode != 'ALL') {
        this.systemService.getNavSubItem(
          res => { !res ? this.subItemOptions = [] : this.subItemOptions = res },
          this.selectedItem.phaseCode,
          this.selectedItem.itemCode
        )
      }
    },
    setWButton(res) {
      this.modOpen = false

      if (!res) res = []
      // console.log(`res: %s`, JSON.stringify(res))

      // 'no' field is generated by v-data-table's items="props" action
      // because of that props.item.no is defined to the data-date' item template
      // it doesn't need to add manually...
      // res.forEach(item => { item['no'] = 0 })
      this.wButton = res

      // at the very first time access or page reloading,
      // it comes twise from 'watcher' and 'mounted' event
      if (this.selectedIndex < -1) {
        ++this.selectedIndex
        // --

        // if this action comes from that add new writable button,
        // right added item should be selected manually.
      } else if (this.selectedIndex == -1) {
        this.setEdit(
          this.wButton.findIndex(
            x => x.buttonCode === this.selectedItem.buttonCode
          )
        )
      } else {
        // if this action comes from that delete existing writable button,
        // select a forward or backward item.
        if (
          this.wButton.findIndex(
            x => x.buttonCode === this.selectedItem.buttonCode
          ) < 0
        ) {
          if (this.wButton.length > 0) {
            if (this.wButton.length >= this.selectedIndex + 1) {
              // Nothing to do...
            } else {
              this.selectedIndex = this.wButton.length - 1
            }
            this.setEdit(this.selectedIndex)
          } else {
            // this.setAdd()
          }
        } else {
          this.setEdit(this.selectedIndex)
        }
      }
    },
    onFiltered(items) {
      // Renumbering for the action of Drag & Drop and search filtering.
      let reNumbering = 0
      items.forEach(_item => {
        this.wButton.find(
          _item_ => _item_.buttonCode == _item.buttonCode
        ).no = ++reNumbering
      })
    },
    onSorted(items) {
      items.forEach((item, index) => {
        item.no = index + 1
      })
    },
    add() {
      this.yes = () => {
        this.msgOpen = false
        this.systemService.putWButton(this.selectedItem, res => {
          this.systemService.getWButton(this.setWButton, this.codePropagated.parentCode, this.codePropagated.code)
        })
        this.yes = () => { }
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Save Changes."
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to save new Writable Button?"
      this.msgInfo.buttonText[0] = "Save"
      this.msgOpen = true
    },
    edit() {
      this.yes = () => {
        this.msgOpen = false
        if (
          this.selectedPrevItem[0].phaseCode != this.selectedItem.phaseCode ||
          this.selectedPrevItem[0].groupCode != this.selectedItem.groupCode ||
          this.selectedPrevItem[0].itemCode != this.selectedItem.itemCode
        ) {
          this.selectedItem.changedCode = true
        } else {
          this.selectedItem.changedCode = false
        }
        this.systemService.updWButton(this.selectedItem, res => {
          this.systemService.getWButton(this.setWButton, this.codePropagated.parentCode, this.codePropagated.code)
        })
        this.yes = () => { }
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Save Changes."
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to save this changes?"
      this.msgInfo.buttonText[0] = "Save"
      this.msgOpen = true
    },
    del() {
      this.yes = () => {
        this.msgOpen = false
        this.systemService.delWButton(this.selectedItem.idx, res => {
          this.systemService.getWButton(this.setWButton, this.codePropagated.parentCode, this.codePropagated.code)
        })
        this.yes = () => { }
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Action Approval"
      this.msgInfo.titleDescription = "Important Notification"
      this.msgInfo.message = "Do you want to delete selected Writable Button?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    querySelections(v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.wButton.filter(e => {
          return ((e.itemName || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1)
        })
        this.loading = false
      }, 500)
    },
    propStatus(props) {
      this.selectedIndex = this.wButton.indexOf(props.item)
      this.selectedItem = Object.assign({}, props.item)

      this.accessUser = []
      this.selectedItem.accessUser.split(",").forEach(id => {
        let member = this.accessUserOptions.find(o => o.id == id)
        if (!!member) this.accessUser.push(member)
      })

      this.accessGroup = []
      this.selectedItem.accessGroup.split(",").forEach(code => {
        let group = this.accessGroupOptions.find(o => o.code == code)
        if (!!group) this.accessGroup.push(group)
      })

      this.prevCodes.phaseCode = this.selectedItem.phaseCode
      this.prevCodes.groupCode = this.selectedItem.groupCode
      this.prevCodes.itemCode  = this.selectedItem.itemCode

      this.phaseChanged(this.selectedItem.phaseCode)
      this.groupChanged(this.selectedItem.groupCode)
      this.itemChanged(this.selectedItem.itemCode)

      if (this.selectedPrevItem.length > 0) {
        if (this.selectedPrevItem[0].buttonCode === props.item.buttonCode) {
          this.modOpen = true
          return
        } else {
          let _selected = props.item
          this.selected = [_selected]
        }
      } else {
        props.selected = !props.selected
      }
      this.selectedPrevItem = [...this.selected]
      this.formode = __C.FORM.EDIT_MODE_MOD
      this.modOpen = true
    },
    setAdd() {

      this.selectedItem = {}
      this.selected = []
      this.selectedPrevItem = []
      this.accessUser = []
      this.accessGroup = []
      this.subItemOptions = []

      // at the very first time access or page reloading,
      // it comes twise from 'watcher' and 'mounted' event.
      // so it should be retained to be increased by them until being -1.
      this.selectedIndex = this.selectedIndex < -1 ? this.selectedIndex : -1
      this.$refs.formHandler.formReset()

      setTimeout(() => {

        if (!['ALL'].includes(this.codePropagated.parentCode)) {
          this.selectedItem.phaseCode = this.codePropagated.parentCode
          this.selectedItem.groupCode = this.codePropagated.groupCode
          this.selectedItem.itemCode  = this.codePropagated.code

          this.prevCodes.phaseCode = this.selectedItem.phaseCode
          this.prevCodes.groupCode = this.selectedItem.groupCode
          this.prevCodes.itemCode  = this.selectedItem.itemCode
        }

        this.phaseChanged(this.selectedItem.phaseCode)
        this.groupChanged(this.selectedItem.groupCode)
        this.itemChanged(this.selectedItem.itemCode)
        this.selectedItem.subItem = 0
  
        this.formode = __C.FORM.EDIT_MODE_NEW
        this.modOpen = true
      })
    },
    setEdit(selectedIndex) {
      // myArray.map(x => x.hello).indexOf('stevie')
      this.selectedIndex = selectedIndex
      this.selectedItem = Object.assign({}, this.wButton[this.selectedIndex])
      this.selected = [Object.assign({}, this.wButton[this.selectedIndex])]
      this.selectedPrevItem = [...this.selected]
      this.formode = __C.FORM.EDIT_MODE_MOD
    },
    resetable() {
      if (this.selectedIndex < 0) {
        if (
          !!this.selectedItem.phaseCode ||
          !!this.selectedItem.groupCode ||
          !!this.selectedItem.itemCode ||
          !!this.selectedItem.accessUser ||
          !!this.selectedItem.accessGroup ||
          !!this.selectedItem.subItem ||
          !!this.selectedItem.reportName ||
          !!this.selectedItem.desc
        )
          return true
        return false
      } else {
        if (
          this.selectedPrevItem[0].phaseCode != this.selectedItem.phaseCode ||
          this.selectedPrevItem[0].groupCode != this.selectedItem.groupCode ||
          this.selectedPrevItem[0].itemCode != this.selectedItem.itemCode ||
          this.selectedPrevItem[0].accessUser != this.selectedItem.accessUser ||
          this.selectedPrevItem[0].accessGroup != this.selectedItem.accessGroup ||
          this.selectedPrevItem[0].subItem != this.selectedItem.subItem ||
          this.selectedPrevItem[0].reportName != this.selectedItem.reportName ||
          this.selectedPrevItem[0].desc != this.selectedItem.desc
        )
          return true
        return false
      }
    },
    reset() {
      if (this.selectedIndex < 0) {
        this.selectedItem = {}
        this.$refs.formHandler.formReset()
        this.accessUser = []
        this.accessGroup = []
        this.subItemOptions = []
      } else {
        this.selectedItem = Object.assign({}, this.selectedPrevItem[0])
      }
    },
    cancel() {
      this.modOpen = false
    },
    isCodeValid(buttonCode) {
      return !this.wButton.find(item => item.buttonCode == buttonCode)
    }
  }
}
</script>
